<template>
  <div>
    <div class="container mb-5">
      <b-col
        lg="6"
        sm="12"
        xs="12"
        md="8"
        offset-lg="3"
        offset-md="2"
      >
        <b-card
          class="hubu-bg-red"
          :header="$t('auth.account')"
          header-tag="header"
        >
          <b-form @submit="registerAccount">
            <div v-if="errors.invalid">
              {{ errors.invalid }}
            </div>
            <b-form-group
              id="input-group-1"
              :label="$t('auth.email_label')"
              label-for="input-1"
              description=""
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                :placeholder="$t('auth.email_label')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              :label="$t('auth.password_label')"
              label-for="input-2"
              :description="$t('auth.password_label')"
            >
              <b-input-group>
                <b-form-input
                  id="input-2"
                  v-model="form.password"
                  :type="form.password_field.type"
                  required
                  minlength="6"
                  :placeholder="$t('auth.password_label')"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="showPassword"
                  >
                    <b-icon :icon="form.password_field.icon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              :label="$t('auth.passwordConfirm_label')"
              label-for="input"
              :description="$t('auth.passwordConfirm_label')"
            >
              <b-input-group>
                <b-form-input
                  id="input"
                  v-model="form.passwordConfirm"
                  :type="form.password_field.type"
                  required
                  minlength="6"
                  :placeholder="$t('auth.passwordConfirm_label')"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="showPassword"
                  >
                    <b-icon :icon="form.password_field.icon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div>
              <b-button
                class="hubu-btn-red mr-2"
                size="md"
                type="submit"
                variant="primary"
              >
                {{ $t("submit") }}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>

      <b-modal
        id="confirm-modal"
        :title="$t('modal.confirm.title')"
        header-bg-variant="red"
        header-text-variant="white"
        body-bg-variant="white"
        body-text-variant="dark"
        footer-bg-variant="white"
        footer-text-variant="red"
      >
        <b-container fluid>
          <b-row class="mb-1 text-center">
            <b-col cols="12">
              <h5 class="text-left">
                {{ heading }}
              </h5>
              <hr />
              {{ message }}
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-left"></p>
          </div>
          <div class="w-100">
            <b-button
              variant="outline-red"
              size="lg"
              class="float-right"
              @click="$bvModal.hide('confirm-modal')"
            >
              {{ $t("submit") }}
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  data () {
    return {
      modal: {
        Heading: "",
        Message: "",
      },
      form: {
        confirmationToken: this.$route.query.confirmationToken,
        email: this.$route.query.email || null,
        password: null,
        passwordConfirm: null,
        password_field: {
          type: "password",
          icon: "eye-slash",
        },
      },
      errors: {
        invalid: null,
      },
    };
  },

  computed: {
    heading: {
      set (v) {
        this.modal.Heading = v;
      },
      get () {
        return this.modal.Heading;
      },
    },
    message: {
      set (v) {
        this.modal.Message = v;
      },
      get () {
        return this.modal.Message;
      },
    },
    user () {
      return this.$store.getters.getUserData;
    },
  },

  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    registerAccount (e) {
      e.preventDefault();
      this.$store
        .dispatch("verifyAccount", { email: this.form.email, confirmationToken: this.$route.query.confirmationToken })
        .then((resp) => {
          if (resp.data == true) {
            this.$router.push({
              name: "Login",
            });
          } else {
            if (this.form.password == this.form.passwordConfirm) {
              this.$store
                .dispatch("userRegister", this.form)
                .then(() => {
                  this.$store.dispatch("userLogin", {
                    email: this.form.email,
                    password: this.form.password,
                  }).then(
                    () => {
                      this.$router.push({
                        name: "Home",
                      });
                    });
                })
                .catch(() => {
                  this.$store
                    .dispatch("userLogin", {
                      email: this.form.email,
                      password: this.form.password,
                    })
                    .then(
                      () => {
                        this.$router.push({
                          name: "Home",
                        });
                      },
                      (err) => {
                        this.errors.invalid = this.$t(
                          err.response.data.message[0].messages[0].id,
                        );
                      },
                    );
                });
            } else {
              this.heading = this.$t("error");
              this.message = this.appText("password_match_error");
              this.$bvModal.show("confirm-modal");
            }
          }
        })
        .catch(() => {});
    },
    showPassword () {
      if (this.form.password_field.type === "password") {
        this.form.password_field.type = "text";
        this.form.password_field.icon = "eye";
      } else {
        this.form.password_field.type = "password";
        this.form.password_field.icon = "eye-slash";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}
</style>